const addQspsToRelativeUrl = (url: string, params: { [key: string]: string | boolean | null }) => {
  const urlParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
      urlParams.set(key, String(params[key]));
    }
  });

  return urlParams.size > 0 ? `${ url }?${ urlParams.toString() }` : url;
};

export { addQspsToRelativeUrl };
