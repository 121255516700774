import { MediaContext } from '@/types/mediaContext';
import { PbsKidsVideo } from '@/types/pbskids-graph';
import { addQspsToRelativeUrl } from '@/utils/urls';

const getVideoCardLink = (videoItem: PbsKidsVideo, context?: MediaContext) => {
  const basePath = context?.id ?
    `/videos/watch/${context.slug}/${context.id}/${videoItem.slug}/${videoItem.id}` :
    `/videos/watch/${videoItem.slug}/${videoItem.id}`;

  const qsps: { [key: string]: string | boolean | null } = {};

  if (context && (context.referringPage || context.loops)) {
    if (context.referringPage) {
      qsps['videopage'] = context.referringPage;
    }
    if (context.loops === true) {
      qsps['post-play'] = 'loop';
    }
  }

  return addQspsToRelativeUrl(basePath, qsps);
};

export { getVideoCardLink };
