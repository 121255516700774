import React from 'react';
import { ReactNode } from 'react';
import StyledIcon from '@/components/base/StyledIcon';
import styles from './MediaBadge.module.scss';

interface Props {
  text: string,
  bgColor?: string,
  icon?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MediaBadgeText: React.FC<Props> = ({
  text,
  bgColor,
  icon,
}) => {
  const showIcon = (): ReactNode => {
    if (icon) {
      return (
        <StyledIcon
          icon={ icon }
          classList={styles.mediaBadgeText}
        />
      );
    }
  };

  return (
    <span
      className={`${ styles.mediaBadgeTextIconWrapper } ${ bgColor || '' }`}
    >
      { showIcon() }
      { text }
    </span>
  );
};

export default MediaBadgeText;
