import React, { useContext } from 'react';
import LanguageLocalizer from '@/utils/language-localization';
import styles from './MediaBadge.module.scss';

interface Props {
  text?: string
  inMasthead?: boolean
}

const MediaBadgeNew: React.FC<Props> = ({
  text, inMasthead,
}) => {
  const { __t } = new LanguageLocalizer( useContext, 'components/base/MediaBadge' );
  return (
    <span
      className={`${styles.mediaBadgeNew} ${inMasthead ? styles.inMasthead : ''}`}
    >
      { text || __t('newBadgeLabel')}
    </span>
  );
};

export default MediaBadgeNew;
